import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { getDisplayName } from './HOC';
import i18n from '../i18n/index';
function withLanguage(WrappedComponent) {
    class LanguageWrapper extends React.Component {
        render() {
            return (
                <I18nextProvider i18n={i18n}>
                    <WrappedComponent {...this.props} />
                </I18nextProvider>
            );
        }
    }
    LanguageWrapper.displayName = `WithLanguage(${getDisplayName(WrappedComponent)})`;

    return LanguageWrapper;
}

export default withLanguage;