import {ROOT_URL} from '../config'
const DEVICE_API_V1 = {
    SIGNUP: "/api/devices/v1/signup"
};
export default {
    signUp(resData) {
        let headers = {
            "Content-Type": "application/json; charset=utf-8"
        };
        return fetch(ROOT_URL + DEVICE_API_V1.SIGNUP, {
            method: 'POST',
            headers,
            body: JSON.stringify(resData)
          }).then(response => {
            return response.json();
        }).catch(error => {
            return error;
        });
    }
};