import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

//import Footer from './containers/Footer';
//import NewsLetter from './components/NewsLetter';
//import Copyright from './components/Copyright';
//import Layout from './containers/Layout';
import withLanguage from './utils/Language';
//import ScrollButton from './components/ScrollButton';

import FingerprintJS from 'fingerprintjs2';
import deviceService  from './services/DeviceService'
import { useEffect } from 'react';
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)
const Header = React.lazy(() => import('./containers/Header'));
const Footer = React.lazy(() => import('./containers/Footer'));
const NewsLetter = React.lazy(() => import('./components/NewsLetter'));
const Copyright = React.lazy(() => import('./components/Copyright'));
const Layout = React.lazy(() => import('./containers/Layout'));
const ScrollButton = React.lazy(() => import('./components/ScrollButton'));
const App = () => {
  useEffect (() => {
    if (localStorage.getItem("X-Zetgoo-Client-ID")) {
      return;
    }
    if (window.requestIdleCallback) {
      requestIdleCallback(function () {
        FingerprintJS.get(function (components) {
            let values = components.map(function (component) { return component.value })
            let visitorId = FingerprintJS.x64hash128(values.join(''), 31)
            localStorage.setItem("X-Zetgoo-Client-Fingerprint",visitorId)
            deviceService.signUp({
              fingerprint: visitorId,
              components: components
            }).then(resObj => {
              if (resObj.status === "OK") {
                localStorage.setItem("X-Zetgoo-Client-ID",resObj.data)
              }
            });
          })
      })
    } else {
      setTimeout(function () {
        FingerprintJS.get(function (components) {
            let values = components.map(function (component) { return component.value })
            let visitorId = FingerprintJS.x64hash128(values.join(''), 31)
            localStorage.setItem("X-Zetgoo-Client-Fingerprint",visitorId)
            //Request to Server for DeviceID
            deviceService.signUp({
              fingerprint: visitorId,
              components: components
            }).then(resObj => {
              if (resObj.status === "OK") {
                localStorage.setItem("X-Zetgoo-Client-ID",resObj.data)
              }
            });
          })  
      }, 500)
    }
  }, []);
  return (
    <>
      <React.Suspense fallback={loading}>
        <Header/>
        <Layout />
        <NewsLetter />
        <Footer/>
        <Copyright />
        <ScrollButton scrollStepInPx="50" delayInMs="16.66"/>
      </React.Suspense>
    </>
  );
}

export default withLanguage(App);

/////https://preview.colorlib.com/theme/onetech/#
////https://phuonglai.com/
/**
 * https://datsolar.com/san-pham/
 */
