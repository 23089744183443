import i18n from 'i18next'
import LanguageDetector from "i18next-browser-languagedetector"
import i18n_en from "./i18n_en.json"
import i18n_vi from "./i18n_vi.json"
i18n.use(LanguageDetector).init({
	resources: {
		en: {
			translations: i18n_en
		},
		vi: {
			translations: i18n_vi
		}
	},
	fallbackLng: "vi",
	debug: false,
	// have a common namespace used around the full app
	ns: ["translations"],
	defaultNS: "translations",
	keySeparator: false, // we use content as keys
	interpolation: {
		escapeValue: false, // not needed for react!!
		formatSeparator: ","
	},
	react: {
		wait: true
	}
});

export default i18n